<template>
  <div style="width: 100%" class="point-data-holder">
    <div style="display: flex; justify-content: center; width: 100% " class="chart-inner-container">
      <!--<img :src="output">-->
      <div class="point-data-control">
        <div>
          <md-button  @click="takeScreenshot" class="md-raised md-primary" >Снимок</md-button>
        </div>
        <div>
          <md-button  @click="hide" class="md-raised md-primary" >Скрыть</md-button>
        </div>
        <div>
          <md-button  @click="up" class="md-raised md-primary" >Вверх</md-button>
        </div>
        <div>
          <md-button  @click="down" class="md-raised md-primary">Вниз</md-button>
        </div>
      </div>
      <div class="point-data-chart" style=""  :ref="'printMe-' + gauge.code">
        <div class="font-weight-bold text-center"><h4>{{gauge.name}} ({{gauge.latitude}}&#176; c.ш. {{gauge.longitude}}&#176; в.д.) (Локальное время)</h4> </div>
        <!--<line-chart :data="pointData" :code="gauge.code" />-->
        <time-chart :point-data="pointData" :type="chartType" :load-date="loadDate" :chart-settings="chartSettings" :code="gauge.code" style="background-color: white" :wave-date="getTime()"/> {{getTime()}}
        <div style="display: flex; justify-content: flex-end; padding-right: 20px">
          <div style="display: flex; flex-wrap: wrap; padding: 5px 0; gap: 10px">
            <!-- @TODO поднять на уровень выше-->
            <md-radio v-model="chartType" :value="1" style="margin: 0">Уровень моря </md-radio>
            <md-radio v-model="chartType" :value="2" style="margin: 0">Скорость измерений</md-radio>
          </div>
        </div>
      </div>
      <div style="" class="point-data-details">
        <!--<div><h4>Текущие данные</h4></div>-->
        <div>
          <table class="latest-details" >
            <tr class="underline">
              <td width="50%">Время последнего измерения</td>
              <td width="100%">{{lastDate}}</td>
            </tr>
            <!--<tr><td colspan="2"> </td></tr>-->
            <tr class="underline">
              <td>Уровень моря</td>
              <td>{{seaLevel}}</td>
            </tr>
            <!--<tr><td colspan="2"></td></tr>-->
            <tr class="underline">
              <td>Скорость измерения уровня моря</td>
              <td>{{changeSpeed}}</td>
            </tr>
            <!--<tr><td colspan="2"> </td></tr>-->
            <tr class="underline">
              <td>Расчетное время прихода волны</td>
              <td>{{getTimeTitle(waveTime)}}</td>
            </tr>
          </table>
        </div>
        <div>Состояние: {{status}}</div>
      </div>

    </div>
  </div>
</template>

<script>
  // import LineChart from "./LineChart";
  import TimeChart from "./TimeChart";
  import html2canvas from 'html2canvas';
  import {getSeaMeasurements} from "../../api/sea-measurement-controller";
  import {getUTCDateSeconds} from "../../utils/timezone";

  import {mapActions, mapGetters} from 'vuex'
  import {getUTCDateTitleWithOffsetFromDate, getUTCDateWithOffsetFromDate} from "../../utils/time";

  // const config = {
  //   type: 'line',
  //   data: data,
  // };

  // // const labels = Utils.months({count: 7});
  // const data = {
  //   labels: ["1 test"],
  //   datasets: [{
  //     label: 'My First Dataset',
  //     data: [65, 59, 80, 81, 56, 55, 40],
  //     fill: false,
  //     borderColor: 'rgb(75, 192, 192)',
  //     tension: 0.1
  //   }]
  // };

  export default {
    name: 'pointData',
    // components: {LineChart},
    components: {TimeChart},
    props: {
      gauge: Object,
      chartSettings: Object,
      waveTime: Number,
      waveHeight: Object
    },
    data() {
      return {
        output: null,
        chartType: 1,
        address: '',
        status: '',
        lastDate: '',
        seaLevel: '',
        changeSpeed: '',
        comeDate: '',
        // chartType: 'level', //'speed'
        pointData: [],
        loadDate: null
      }
    },
    computed: {
      ...mapGetters(['getFirstSignalDate'])
    },
    watch: {
      gauge(newVal) {
        if (newVal && newVal.code) {
          this.loadData()
        }
      }
    },
    methods: {
      ...mapActions(['showInfoAlert']),
      refresh() {
        return null
      },
      getTime() {
        return this.waveTime ? getUTCDateWithOffsetFromDate(this.getFirstSignalDate, this.waveTime) : null
      },
      getTimeTitle(offset) {
        return getUTCDateTitleWithOffsetFromDate(this.getFirstSignalDate, offset)
      },
      async loadData() {
        let startDate = new Date()
        startDate.setHours(startDate.getHours() - this.chartSettings.receiveInterval)
        let result = await getSeaMeasurements(this.gauge.code, startDate.toISOString(), new Date().toISOString())
        this.pointData = result.payload? result.payload : []
        this.loadDate = new Date()
        if (this.pointData.length && this.pointData[this.pointData.length - 1]) {
          this.lastDate = getUTCDateSeconds(this.pointData[this.pointData.length - 1].measureDate) + ' ВСВ'
          this.seaLevel = (+this.pointData[this.pointData.length - 1].seaLevel).toFixed(2)

          // console.log('sea change speed', this.pointData[this.pointData.length - 1], this.pointData[this.pointData.length - 1].seaLevelSpeed)
          if (this.pointData[this.pointData.length - 1].seaLevelSpeed) {
            this.changeSpeed = (+this.pointData[this.pointData.length - 1].seaLevelSpeed).toFixed(2)
          }
        }
      },
      async takeScreenshot() {
        const el = this.$refs['printMe-' + this.gauge.code]
        // add option type to get the image version
        // if not provided the promise will return
        // the canvas.
        // const options = {
        //   type: 'dataURL'
        // }
        // this.output = await html2canvas(el, options);
        // let vc = this
        // let filename = 'Reporte de ' + vc.name + '.png';
        html2canvas(el).then(canvas => {
          // vc.saveAs(canvas.toDataURL(), filename);
          var image = canvas.toDataURL("image/png");//.replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.

          var a = document.createElement('a');
          a.href = image;
          a.download = 'screenshot_' + this.gauge.code + '_' + new Date().toISOString() + '.png';
          document.body.appendChild(a);
          a.click();

          // window.location.href=image; // it will save locally
        }).catch(() => {
          this.showInfoAlert("Ошибка при сохранении снимка графика")
        });
      },
      hide() {
        return this.$emit('hideGauge', this.gauge)
      },
      up() {
        this.$emit('onUp', this.gauge.code)
      },
      down() {
        this.$emit('onDown', this.gauge.code)
      }
    },
    mounted() {
      if (this.gauge) {
        this.loadData()
      }
    }

  }
</script>

<style scoped>
  .point-data-holder {
    background-color: lightgrey;
  }

  @media (min-width: 1000px) {

    .point-data-chart {
      max-width: 90vw;
      padding-right: 10px;
      width: calc(99vw - 350px)
    }

    .chart-inner-container {
      flex-direction: row;
    }
    .point-data-control {
      /*padding-top: 16px;*/
      padding-right: 110px;
      width: 80px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-top: 20px;
      justify-content: flex-start;
    }

    .point-data-details {
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-right: 5px;

    }

  }
  @media (max-width: 999px) {
    .point-data-holder {
       padding-bottom: 10px;
     }
    .point-data-chart {
      /*height: 500px;*/
      width: 97vw
    }

    .chart-inner-container {
      flex-direction: column;
      border-bottom: 2px solid black;
    }
    .point-data-control {
      padding-right: 10px;
      gap: 10px;
      /*margin-bottom: 140px;*/
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
    }


    /*.point-data-control div {*/
      /*flex: 0 0 45%;*/
    /*}*/

    .point-data-details {
      width: 90vw;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0 20px;
    }

    table.latest-details {
      padding-right: 4px;
      width: 100%;
      margin-bottom: 20px;
    }
  }



  .point-data-info {
    background-color: white;
    width: 300px
  }

  tr td {
    padding: 5px 0;
  }

  tr.underline {
    border-bottom: 1px solid black;
  }
</style>